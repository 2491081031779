// extracted by mini-css-extract-plugin
export var calculator = "wq_brl";
export var calculator_areas = "wq_bzl";
export var calculator_container = "wq_bxl";
export var calculator_cta = "wq_bvl";
export var calculator_inner = "wq_bsl";
export var calculator_saving = "wq_bCl";
export var calculator_sliders = "wq_bBl";
export var calculator_submit = "wq_byl";
export var calculator_title = "wq_btl";
export var calculator_titleForm = "wq_bwl";