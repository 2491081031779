import React from 'react'
import { Container } from 'react-bootstrap'

import FeatureCard from '../FeatureCard'
import * as s from './Features.module.scss'

const Features = ({ heading, cards }) => (
  <section className={s.features}>
    <Container className={s.features_inner}>
      <h2
        className={s.features_heading}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <ul className={s.features_list}>
        {cards?.map((item) => (
          <FeatureCard key={item.heading} {...item} />
        ))}
      </ul>
    </Container>
  </section>
)

export default Features
