import React from 'react'
import { FormGroup } from 'react-bootstrap'
import { useLocalization } from 'gatsby-theme-i18n'

import { nFormatter } from '~utils'

import * as s from './CalculatorResult.module.scss'

const CalculatorResult = ({
  absolute,
  representationRelative,
  totalSavings,
  totalSavingsPercent,
}) => {
  const { locale } = useLocalization()

  return (
    <FormGroup className={s.result}>
      <h2 className={s.result_title}>
        <span>{absolute?.textBefore}</span>
        <span className={s.result_title_value}>{`${
          totalSavings ? nFormatter(totalSavings, locale) : 0
        } ${absolute?.unit}`}</span>
      </h2>
      <ul className={s.result_container}>
        <p>{absolute?.textAfter}</p>
        <p>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: representationRelative?.textBefore,
            }}
          />
          <span className={s.result_container_value}>
            {totalSavingsPercent ? totalSavingsPercent.toFixed(2) : 0}%
          </span>
        </p>
      </ul>
    </FormGroup>
  )
}

export default CalculatorResult
