import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import CardInner from './CardInner'
import * as s from './FeatureCard.module.scss'

const FeatureCard = ({ heading, link, text }) => (
  <li className={cn({ [s.feature_card]: !link })}>
    {link ? (
      <Link
        to={link}
        className={cn(s.feature_card_link, { [s.feature_card]: link })}
      >
        <CardInner heading={heading} text={text} />
      </Link>
    ) : (
      <CardInner heading={heading} text={text} />
    )}
  </li>
)

export default FeatureCard
