import React from 'react'
import { Container } from 'react-bootstrap'

import * as s from './Solution.module.scss'

const Solution = ({ heading, text }) => (
  <section className={s.solution}>
    <Container className={s.solution_inner}>
      <h2
        className={s.solution_title}
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <p className={s.solution_descr}>{text}</p>
    </Container>
  </section>
)

export default Solution
