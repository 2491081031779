import React from 'react'
import { Ratio } from 'react-bootstrap'
import cn from 'classnames'

import { getVideoUrl } from './utils'
import * as s from './Video.module.scss'

const Video = ({ video, fallback, className }) => (
  <section className={cn(s.section, className)}>
    <Ratio aspectRatio="16x9" className={s.aspectRationContainer}>
      <div className={s.wrapper}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls id="vid" playsInline width="auto" height="100%">
          <source src={getVideoUrl(video)} type="video/mp4" />
          <source src={getVideoUrl(fallback)} type="video/webm" />
        </video>
      </div>
    </Ratio>
  </section>
)

export default Video
