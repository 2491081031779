import { useEffect, useState } from 'react'

const useCalculatorSliders = (sliders, areas) => {
  const defaultSelectedArea = 0

  const [selectedAreaIndex, setSelectedAreaIndex] =
    useState(defaultSelectedArea)
  const [sliderValues, setSliderValues] = useState({})

  useEffect(() => {
    const defaultSliderValues = Object.fromEntries(
      sliders.map((slider) => [slider.id, slider.defaultValue ?? 0])
    )

    defaultSliderValues.cost =
      (defaultSliderValues.main + defaultSliderValues.basement) *
      areas[defaultSelectedArea].referenceCostPerSqM

    setSliderValues(defaultSliderValues)
  }, [sliders])

  const handleChangeSelectedArea = (i) => {
    const { referenceCostPerSqM } = areas[i]

    setSelectedAreaIndex(i)
    setSliderValues((prevState) => ({
      ...prevState,
      cost:
        (parseFloat(prevState.main) + parseFloat(prevState.basement)) *
        referenceCostPerSqM,
    }))
  }

  const handleChangeSlider = (e, key, refCost) => {
    const costKey = key === 'main' ? 'basement' : 'main'
    setSliderValues((prevState) => ({
      ...prevState,
      cost:
        key !== 'cost' &&
        (parseFloat(prevState[costKey]) + parseFloat(e.target.value)) * refCost,
      [key]: e.target.value,
    }))
  }

  const { referenceCostPerSqM } = areas[selectedAreaIndex]

  return {
    selectedAreaIndex,
    sliderValues,
    handleChangeSelectedArea,
    handleChangeSlider,
    referenceCostPerSqM,
  }
}

export default useCalculatorSliders
