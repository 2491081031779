/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import gsap from 'gsap'
import useMatchMedia from './useMatchMedia'

const useSnapScroll = (snapSelector, threshold = 200) => {
  const isCreateScroll = useMatchMedia(
    '(max-width: 639px), (min-width: 1200px)'
  )
  const ignoreScrollEvent = useRef(false)

  function createScroll() {
    const sections = document.querySelectorAll(snapSelector)

    const debouncedHandleScroll = debounce(() => {
      let closestSection = null
      let closestOffset = Infinity

      for (let i = 0; i < sections.length; i += 1) {
        const el = sections[i]

        const offsetSectionCenterFromCenter = Math.abs(
          window.pageYOffset +
            window.innerHeight / 2 -
            el.offsetTop -
            el.getBoundingClientRect().height / 2
        )

        if (offsetSectionCenterFromCenter < closestOffset) {
          closestSection = el
          closestOffset = offsetSectionCenterFromCenter
        }
      }

      const offsetSectionTopFromViewTop =
        window.pageYOffset - closestSection?.offsetTop

      const offsetSectionBottomFromViewBottom =
        window.pageYOffset +
        window.innerHeight -
        (closestSection?.offsetTop +
          closestSection?.getBoundingClientRect().height)

      const offsetSectionTopFromViewBottom =
        window.pageYOffset + window.innerHeight - closestSection?.offsetTop

      const offsetSectionBottomFromViewTop =
        window.pageYOffset -
        closestSection?.offsetTop -
        closestSection?.getBoundingClientRect().height

      const doesNotFitInView =
        offsetSectionTopFromViewTop > 5 && offsetSectionBottomFromViewBottom < 5

      const allowSnapping =
        offsetSectionTopFromViewBottom > threshold &&
        offsetSectionBottomFromViewTop < -threshold &&
        !doesNotFitInView

      const isLargerThanView =
        closestSection?.getBoundingClientRect().height > window?.innerHeight

      const offsetY = isLargerThanView
        ? 0
        : window.innerHeight / 2 -
          closestSection?.getBoundingClientRect().height / 2

      if (allowSnapping) {
        ignoreScrollEvent.current = true

        gsap.to(window, {
          scrollTo: { y: closestSection, offsetY },
          ease: 'power2',
          onComplete: () =>
            setTimeout(() => {
              ignoreScrollEvent.current = false
            }, 200),
        })
      }
    }, 200)

    const handleScroll = () => {
      if (!ignoreScrollEvent.current) {
        debouncedHandleScroll()
      }
    }

    const preventDefault = (e) => {
      if (ignoreScrollEvent.current) {
        e.preventDefault()
        return false
      }

      return true
    }

    window.addEventListener('scroll', handleScroll, { passive: false })
    window.addEventListener('DOMMouseScroll', preventDefault, {
      passive: false,
    })
    window.addEventListener('wheel', preventDefault, { passive: false })
    window.addEventListener('touchmove', preventDefault, { passive: false })
    window.addEventListener('keydown', preventDefault, { passive: false })
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('DOMMouseScroll', preventDefault)
      window.removeEventListener('wheel', preventDefault)
      window.removeEventListener('touchmove', preventDefault)
      window.removeEventListener('keydown', preventDefault)
    }
  }

  useEffect(() => {
    let killScroll
    if (isCreateScroll) killScroll = createScroll()

    return () => killScroll?.()
  }, [isCreateScroll])
}

export default useSnapScroll
