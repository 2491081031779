import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Button, Container } from 'react-bootstrap'
import cn from 'classnames'
import gsap from 'gsap'

import { getCloudinaryVideoLink } from '~utils'
import { CLOUDINARY_FORMAT, CLOUDINARY_PREFIX } from '~utils/constants'

import * as s from './Hero.module.scss'

const Hero = (props) => {
  const {
    heading,
    navigationButtons: { buttonPrimary, buttonSecondary },
    videoShortcut,
    storeLinks,
    storeImage,
    mediaLaptop,
    mediaPhone,
  } = props

  const handleScroll = (e) => {
    e.preventDefault()

    gsap.to(window, { scrollTo: e.currentTarget.hash, ease: 'power2' })
  }

  const handleScrollToVideo = (e) => {
    e.preventDefault()

    const vidElement = document.querySelector(e.currentTarget.hash)
    gsap.to(window, {
      scrollTo: {
        y: vidElement,
        offsetY:
          window.innerHeight / 2 -
          vidElement.getBoundingClientRect().height / 2,
      },
      ease: 'power2',
    })

    vidElement.volume = 0
    vidElement.play()

    gsap.to(vidElement, { volume: 0.7, duration: 2 })
  }

  const laptopVidWebm = getCloudinaryVideoLink(
    mediaLaptop.videoUrl,
    CLOUDINARY_PREFIX.LAPTOP,
    CLOUDINARY_FORMAT.WEBM
  )

  const laptopVidMp4 = getCloudinaryVideoLink(
    mediaLaptop.videoUrl,
    CLOUDINARY_PREFIX.LAPTOP,
    CLOUDINARY_FORMAT.MP4
  )

  const phoneVidWebm = getCloudinaryVideoLink(
    mediaPhone.videoUrl,
    CLOUDINARY_PREFIX.PHONE,
    CLOUDINARY_FORMAT.WEBM
  )

  const phoneVidMp4 = getCloudinaryVideoLink(
    mediaPhone.videoUrl,
    CLOUDINARY_PREFIX.PHONE,
    CLOUDINARY_FORMAT.MP4
  )

  return (
    <section className={s.hero}>
      <Container className={s.hero_inner}>
        <div className={s.hero_content}>
          <h1>{heading}</h1>
          <div className={s.hero_buttons}>
            <div className={s.hero_btn}>
              <Button href="#booking" onClick={handleScroll} variant="primary">
                {buttonPrimary?.text}
              </Button>
            </div>
            <a
              className={s.hero_link}
              href="#calculator"
              onClick={handleScroll}
            >
              {buttonSecondary?.text}
            </a>
          </div>
          <a className={s.hero_video} href="#vid" onClick={handleScrollToVideo}>
            <span className={s.hero_video_prev}>
              <GatsbyImage
                as="span"
                image={videoShortcut?.thumbnail?.gatsbyImage}
                alt="video"
                objectFit="contain"
                style={{ display: 'block' }}
              />
            </span>
            <span className={s.hero_video_title}>{videoShortcut?.text}</span>
          </a>
          <a
            className={s.hero_appstore}
            href={storeLinks?.linkAppstore}
            target="_blank"
            rel="noreferrer"
          >
            <GatsbyImage
              image={storeImage?.gatsbyImage}
              alt="appstore"
              objectFit="contain"
              style={{ display: 'block' }}
            />
          </a>
        </div>
        <div className={s.hero_pic}>
          <div className={cn(s.hero_pic_item, s.hero_pic_item__laptop)}>
            <div className={s.videoWrapper}>
              <GatsbyImage
                image={mediaLaptop?.placeholder?.gatsbyImage}
                alt="mobile"
                style={{ display: 'block' }}
              />
              {!!laptopVidWebm && (
                <video
                  className={s.video}
                  width="1034px"
                  height="600px"
                  muted
                  autoPlay
                  loop
                  playsInline
                >
                  <source src={laptopVidWebm} type="video/webm" />
                  <source src={laptopVidMp4} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
          <div className={cn(s.hero_pic_item, s.hero_pic_item__mobile)}>
            <div className={s.videoWrapper}>
              <GatsbyImage
                image={mediaPhone?.placeholder?.gatsbyImage}
                alt="mobile"
                style={{ display: 'block' }}
              />
              {!!phoneVidWebm && (
                <video
                  className={s.video}
                  width="235px"
                  height="100%"
                  muted
                  autoPlay
                  loop
                  playsInline
                >
                  <source src={phoneVidWebm} type="video/webm" />
                  <source src={phoneVidMp4} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Hero
