/* eslint-disable react/no-danger */
import React from 'react'

import * as s from './FeatureCard.module.scss'

const CardInner = ({ heading, text }) => (
  <>
    <p
      className={s.feature_card_title}
      dangerouslySetInnerHTML={{ __html: heading }}
    />
    <p
      className={s.feature_card_descr}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </>
)

export default CardInner
