import React from 'react'
import Layout from '~components/Layout'

import Reviews from '~components/Reviews'
import Partners from '~components/Partners'
import useSnapScroll from '~hooks/useSnapScroll'

import Problem from './components/Problem'
import Solution from './components/Solution'
import Features from './components/Features'
import Hero from './components/Hero'
import Video from './components/Video'
import Calculator from './components/Calculator'
import S from '../../components/seo'

const Home = (props) => {
  const {
    header,
    headerMobileExtras,
    footer,
    socials,
    cellar,
    contactInfo,
    cookiesOffcanvas,
    bookingForm,
    bookADemo,
    formMessages,
    seo,
    sectionHeroHomepage,
    sectionProblem,
    sectionSolution,
    sectionFeatures,
    sectionVideo,
    sectionReviews,
    sectionPartners,
    sectionCalculator,
    sectionContactForm,
    emailReport,
    reviews,
    calculatorForm,
    contactUsForm,
  } = props

  useSnapScroll('.section-video', 350)

  return (
    <Layout
      withBooking
      withCta
      {...{
        header,
        headerMobileExtras,
        footer,
        socials,
        cellar,
        contactInfo,
        cookiesOffcanvas,
        bookingForm,
        bookADemo,
        formMessages,
        sectionContactForm,
        contactUsForm,
      }}
    >
      <S {...seo} />
      {sectionHeroHomepage && <Hero {...sectionHeroHomepage} />}
      {sectionProblem && <Problem {...sectionProblem} />}
      {sectionSolution && <Solution {...sectionSolution} />}
      {sectionFeatures && <Features {...sectionFeatures} />}
      {sectionVideo && <Video {...sectionVideo} className="section-video" />}
      {sectionReviews && (
        <Reviews {...sectionReviews} reviews={reviews.nodes} />
      )}
      {sectionPartners && <Partners {...sectionPartners} />}
      {sectionCalculator && (
        <Calculator
          {...sectionCalculator}
          calculatorForm={calculatorForm}
          formMessages={formMessages?.formMessages?.formMessages?.sendReport}
          emailReport={emailReport}
        />
      )}
    </Layout>
  )
}

export default Home
