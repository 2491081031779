/* eslint-disable react/no-danger */
import React from 'react'
import { Form } from 'react-bootstrap'

import * as s from './Slider.module.scss'

const Slider = ({ label, min, max, step, unit, value, onChange }) => {
  return (
    <div className={s.slider_container}>
      <div className={s.slider_heading}>
        <p
          className={s.slider_label}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        <div className={s.slider_units}>
          <span>{parseFloat(value).toLocaleString('en')}</span>
          <span dangerouslySetInnerHTML={{ __html: unit }} />
        </div>
      </div>
      <div className={s.slider_container}>
        <span className={s.slider_track}>
          <span
            style={{ width: `${(value / max) * 100}%` }}
            className={s.slider_progress}
          />
        </span>
        <Form.Range
          max={max}
          min={min ?? 0}
          step={step}
          value={value}
          onChange={onChange}
          className={s.slider}
        />
      </div>
    </div>
  )
}

export default Slider
