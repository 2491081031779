/* eslint-disable react/no-danger */
import React from 'react'
import { Container } from 'react-bootstrap'

import * as s from './Problem.module.scss'

const Problem = ({ heading, text }) => (
  <section className={s.problem}>
    <Container className={s.problem_inner}>
      <h2 className={s.problem_title}>{heading}</h2>
      <p
        className={s.problem_descr}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Container>
  </section>
)

export default Problem
