const useCalculateSavings = ({
  sliderValues,
  areas,
  selectedAreaIndex,
  savingsCoefficients,
}) => {
  const { deliveryCoefficients, name: projectType } = areas[selectedAreaIndex]

  const { savingsBooking, savingsWasteManagement } = savingsCoefficients

  const {
    hourlyWage: SPECIALIST_COST_BOOKING,
    coefficient: BOOKING_COEFFICIENT,
  } = savingsBooking

  const {
    hourlyWage: SPECIALIST_COST_WASTE_MANAGEMENT,
    coefficient: WASTE_MANAGEMENT_COEFFICIENT,
  } = savingsWasteManagement

  const TIME_TO_BOOK_HOURS = savingsBooking.timePerDelivery / 60
  const TIME_FOR_WASTE_MANAGEMENT_HOURS =
    savingsWasteManagement.timePerDelivery / 60

  const { main: mainArea, basement: basementArea, cost } = sliderValues
  const { main: mainCoeff, basement: basementCoeff } = deliveryCoefficients

  const mainDeliveries = mainArea * mainCoeff
  const basementDeliveries = basementArea * basementCoeff

  const totalDeliveries = mainDeliveries + basementDeliveries

  const savedOnBooking =
    BOOKING_COEFFICIENT *
    totalDeliveries *
    TIME_TO_BOOK_HOURS *
    SPECIALIST_COST_BOOKING

  const savedOnWasteManagement =
    WASTE_MANAGEMENT_COEFFICIENT *
    totalDeliveries *
    TIME_FOR_WASTE_MANAGEMENT_HOURS *
    SPECIALIST_COST_WASTE_MANAGEMENT

  const totalSavings = savedOnBooking + savedOnWasteManagement
  const totalSavingsPercent = (totalSavings / cost) * 100

  const extraFormData = {
    inputs: {
      projectType,
      mainArea,
      basementArea,
      projectCost: cost,
      deliveryCoefficients,
    },
    deliveryBooking: {
      coefficient: BOOKING_COEFFICIENT,
      count: totalDeliveries,
      timePerOperation: TIME_TO_BOOK_HOURS,
      specialistCost: SPECIALIST_COST_BOOKING,
      saved: savedOnBooking,
    },
    wasteManagement: {
      coefficient: WASTE_MANAGEMENT_COEFFICIENT,
      count: totalDeliveries,
      timePerOperation: TIME_FOR_WASTE_MANAGEMENT_HOURS,
      specialistCost: SPECIALIST_COST_WASTE_MANAGEMENT,
      saved: savedOnWasteManagement,
    },
    overallSavings: { absolute: totalSavings, relative: totalSavingsPercent },
  }

  return { totalSavings, totalSavingsPercent, extraFormData }
}

export default useCalculateSavings
